import CryptoJS from 'crypto-js';

// 设置加密密钥（确保密钥安全）
const secretKey = 'tianzi_club_pc';

// 保存原始的 setItem 和 getItem 方法
const originalSetItem = localStorage.setItem;
const originalGetItem = localStorage.getItem;

// 重写 setItem：加密所有存储数据
localStorage.setItem = function (key, value) {
    // 将存储的数据转换为字符串并加密
    const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(value), secretKey).toString();
    originalSetItem.call(this, key, encryptedValue);
};

// 重写 getItem：解密所有读取数据
localStorage.getItem = function (key) {
    const storedValue = originalGetItem.call(this, key);
    if (storedValue) {
        try {
            // 尝试解密数据并将其转换回原始格式
            const bytes = CryptoJS.AES.decrypt(storedValue, secretKey);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (e) {
            return null;
        }
    }
    return null;
};

