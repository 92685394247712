import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        platformInfo: localStorage.getItem('platformInfo') ? JSON.parse(localStorage.getItem('platformInfo')) : {}, //平台信息
        navBarInfo: [],  //导航栏信息
        token: localStorage.getItem('token') || '',
        refreshToken: localStorage.getItem('refreshToken') || '',
        userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},    //个人信息
        bagInfo: localStorage.getItem('bagInfo') ? JSON.parse(localStorage.getItem('bagInfo')) : {
            count: 0,
            winTotal: 0
        },   //钱包信息
        showLogin: false,    //展示登录
        showDeposit: false,  //存款弹框
        showWithdrawMoney: false,  //取款弹框
        showCollectionSettings: false,  //收款设置
        showAddAccount: false,  //添加账户
        webViewUrl: sessionStorage.getItem('webViewUrl') || '',  //游戏路径
        webViewConfig: sessionStorage.getItem('webViewConfig') ? JSON.parse(sessionStorage.getItem('webViewConfig')) : {},  //游戏配置
    },
    getters: {},
    mutations: {
        setStateVal(state, payload) {
            state[payload.prop] = payload.value
        }
    },
    actions: {},
    modules: {}
})
