import axios from 'axios';
import {refreshTokenApi} from '@/api'

import {Message} from 'element-ui';

import NProgress from 'nprogress'; // 引入 NProgress
import 'nprogress/nprogress.css';
import fa from "element-ui/src/locale/lang/fa";  // 引入 NProgress 样式

// 创建axios实例
const service = axios.create({
    // baseURL: window?.configGlobal?.HTTP_HOST || 'https://api.example.com', // API的base_url
    timeout: 10000 // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        NProgress.start(); // 开始显示进度条

        //修改请求地址
        config.baseURL = window?.configGlobal?.HTTP_HOST
        // 在请求头中添加token
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `${token}`; // 让每个请求携带token
        }
        return config;
    },
    error => {
        NProgress.done();  // 如果请求错误，结束进度条

        // 处理请求错误
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        NProgress.done();  // 请求成功，结束进度条

        const res = response.data

        // 通过响应码判断请求是否成功
        if (res.code !== 0) {
            Message({
                message: res.message || '网络错误，请重试',
                type: 'error'
            })
        }
        return Promise.resolve(res)
    },
    async (error) => {
        NProgress.done();  // 如果响应错误，结束进度条

        const _loginFail = () => {
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('userInfo')

            // location.reload()
            //回到首页
            location.href = '/'
            // return Promise.resolve({}); // 返回一个成功的 Promise
        }

        if (error.status === 403) {
            //未登录  账号被挤
            Message({
                message: '登录失效，请重新登录',
                type: 'error',
                onClose() {
                    _loginFail()
                }
            })
            return Promise.resolve(error); // 返回一个成功的 Promise
        }
        if (error.status === 401) {
            //重新刷新token
            let _userInfo = JSON.parse(localStorage.getItem('userInfo'))
            let refreshTokenApiRes = await refreshTokenApi({
                userId: _userInfo.userId,
                token: localStorage.getItem('token')
            })
            if (!refreshTokenApiRes.data) {
                Message({
                    message: error.message || '网络错误，请重试',
                    type: 'error',
                    onClose() {
                        _loginFail()
                    }
                })
                return Promise.resolve(error); // 返回一个成功的 Promise
            }
            localStorage.setItem('token', JSON.stringify(refreshTokenApiRes.data.token))
            store.commit('setPropsVal', {
                prop: 'token',
                value: refreshTokenApiRes.data.token
            })

            // return Promise.resolve({}); // 返回一个成功的 Promise
            //刷新一下当前页面
            window.location.reload(true)
            return Promise.resolve(error); // 返回一个成功的 Promise
        }
        // 处理响应错误
        Message({
            message: error.message || '网络错误，请重试',
            type: 'error',
            duration: 1000
        });
        //不抛出异常
        return Promise.resolve(error); // 返回一个成功的 Promise
    }
);


export default service;
